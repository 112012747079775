import { nn } from './string'

export const asHHMM = date =>
  [
    date.getHours(),
    date.getMinutes(),
  ].map(nn).join(':')

export const asDDMMYY = date =>
  [
    date.getDate(),
    date.getMonth() + 1,
    String(date.getFullYear()).substr(-2),
  ].map(nn).join('/')

const startOfDay = date => {
  const cloned = new Date(date)
  cloned.setHours(0, 0, 0, 0)
  return cloned
}

export const asHHMMorDDMMYYYY = date =>
  startOfDay(date) < startOfDay(Date.now()) ?
    asDDMMYY(date) :
    asHHMM(date)
