import React from 'react'
import cx from 'classnames'
import Button from './Button'
import Avatar from '../Avatar'

import style from './connecting.module.scss'

export default ({ user, peer, onHangUp, onPickUp, isIncomingRequest }) => (
  <div className={ style.main }>
    <div className={ style.content }>
      <Avatar className={ style.userAvatar } user={ user } />
      <div className={ style.peer }>
        <Avatar className={ style.peerAvatar } user={ peer } />
        <div className={ style.peerName }>{ peer.firstName } { peer.lastName }</div>
        <div className={ style.peerCenters }>{ peer.centers }</div>
      </div>
      <div className={ style.actions }>
        {
          isIncomingRequest ?
            <Button isPickUp onClick={ onPickUp } /> :
            null
        }
        <Button isHangUp onClick={ onHangUp } />
      </div>
    </div>
  </div>
)