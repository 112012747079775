import React from 'react'
import cx from 'classnames'
import PhoneIcon from '~/assets/images/icon/phone.svg'
import CommentIcon from '~/assets/images/icon/comment.svg'
import style from './button.module.scss'

export default ({ className, onClick, isToggleText, isHangUp, isPickUp }) => (
  <button
    className={ cx(style.main, {
      [style.hangup]: isHangUp,
      [style.pickup]: isPickUp,
      [style.toggleText]: isToggleText,
    }, className) }
    onClick={ onClick }
  >
  {
    isHangUp || isPickUp ?
      <PhoneIcon /> :
      isToggleText ?
        <CommentIcon /> :
        null
  }
  </button>
)
