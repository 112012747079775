import React, { useState, useEffect } from 'react'
import { get, last, pick, findLastIndex } from 'lodash'
import Loading from 'components/common/Loading'
import Text from './Text'
import {
  getAllMessages,
  getChannel,
  getLastConsumedMessageIndex,
  autoJoinChannelWhenInvited
} from '~/service/chat'
import style from './channel.module.scss'

export default ({ client, ...props }) => {
  const [channel, setChannel] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const init = async () => {
    setIsLoading(true)
    const channel = await getChannel(client, props.user, props.peer)
    await autoJoinChannelWhenInvited(channel)
    setChannel(channel)
    setIsLoading(false)
  }

  useEffect(() => {
    init()
  }, [get(props, 'peer.twilioId')])

  return (
    <div className={ style.main }>
    {
      isLoading || !channel ?
        <Loading /> :
        <ChannelMessages
          key={ channel.uniqueName }
          channel={ channel }
          {...props}
        />
    }
    </div>
  )
}

const ChannelMessages = ({channel, user, peer, twilioAccessToken, onClose, onRequestVideo, hasActiveVideoCall }) => {
  const [messages, setMessages] = useState([])
  const [lastConsumedMessageIndex, setLastConsumedMessageIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const addMessageHandler = async added => {
    setMessages(existing => [...existing, added])
    await channel.setAllMessagesConsumed()
    const lastIndex = await getLastConsumedMessageIndex(channel, user)
    setLastConsumedMessageIndex(lastIndex)
  }

  const handleSubmitMessage = message => {
    if (channel) {
      channel.sendMessage(message, {
        ...pick(user, ['firstName', 'lastName', 'picture']),
        to: peer.twilioId,
      })
    }
  }

  const handleAllMessagesConsumed = () => channel.setAllMessagesConsumed()

  const init = async () => {
    setIsLoading(true)
    setLastConsumedMessageIndex(await getLastConsumedMessageIndex(channel, user))
    setMessages(await getAllMessages(channel))
    channel.on('messageAdded', addMessageHandler)
    setIsLoading(false)
  }

  useEffect(() => {
    init()
    return () => channel.removeListener('messageAdded', addMessageHandler)
  }, [])

  return (
    !isLoading ?
      <Text
        user={ user }
        peer={ peer }
        messages={ messages }
        lastConsumedMessageIndex={
          Math.max(lastConsumedMessageIndex, findLastIndex(messages, ({ author }) => author === user.twilioId))
        }
        onClose={ onClose }
        hasActiveVideoCall={ hasActiveVideoCall }
        onRequestVideo={ onRequestVideo }
        onSubmitMessage={ handleSubmitMessage }
        onAllMessagesConsumed={ handleAllMessagesConsumed }
      /> :
      null
  )
}
