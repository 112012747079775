import React from 'react'
import { Trans } from '@lingui/macro'
import { get, take } from 'lodash'
import { withLocale } from 'components/common/Locale'
import CloseIcon from '~/assets/images/icon/close.svg'
import Users from './Users'
import style from './suggestedusers.module.scss'

export default withLocale(({ i18n, items, onSelectItem, onClose }) => (
  <div className={ style.main }>
    <div className={ style.header }>
      <Trans>Contactos sugeridos</Trans>
      <div
        onClick={ onClose }
        className={ style.close }
        role="button"
      >
        <CloseIcon />
      </div>
    </div>
    <Users
      items={ take(items, 3) }
      onSelectItem={ onSelectItem }
      isSuggestion
    />
  </div>
))