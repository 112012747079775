import React from 'react'
import cx from 'classnames'
import Avatar from './Avatar'
import Title from './Title'
import WriteIcon from '~/assets/images/icon/write.svg'
import { asHHMMorDDMMYYYY } from '~/utils/date'
import style from './user.module.scss'

export default ({ className, isActive, user, onClick, isSuggestion, dateUpdated, hasUnconsumedMessages }) => (
  <div role="listitem" onClick={ onClick } className={ cx(style.main, {
    [style.isActive]: isActive,
    [style.isSuggestion]: isSuggestion,
  }, className) }>
    <Avatar user={ user } className={ style.avatar } />
    <Title user={ user } className={ style.title } />

    {
      isSuggestion ?
        <div className={ style.write } role="button" onClick={ onClick }>
          <WriteIcon />
        </div> :
        null
    }
    {
      hasUnconsumedMessages && !isActive ?
        <div className={ style.unconsumedMessagesIndicator } /> :
        null
    }
    {
      dateUpdated ?
        <span className={ style.date }>{ asHHMMorDDMMYYYY(new Date(dateUpdated)) }</span> :
        null
    }
  </div>
)