import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { flowRight, get, map, uniqBy } from 'lodash'
import { Trans } from '@lingui/macro'
import { withLocale } from 'components/common/Locale'
import { withAuth } from 'components/common/Auth'
import Loading from 'components/common/Loading'
import {
  getClient,
  getConversations,
  autoJoinChannelWhenInvited,
} from '~/service/chat'
import {
  getSuggestedUsers
} from '~/service/user'
import { withTwilioClient } from 'components/common/TwilioClient'
import Channel from './Channel'
import Sidebar from './Sidebar'
import Video from './Video'
import style from './chat.module.scss'

const Chat = ({ client, i18n, user = {}, accessToken, twilioAccessToken }) => {
  const [selectedUser, setSelectedUser] = useState(undefined)
  const [recentUsers, setRecentUsers] = useState([])
  const [suggestedUsers, setSuggestedUsers] = useState([])
  const [activeVideoCallPeer, setActiveVideoCallPeer] = useState(false)
  const [outgoingRequestPeer, setOutgoingRequestPeer] = useState(undefined)
  const [showTextWindowDuringVideo, setShowTextWindowDuringVideo] = useState(false)

  const handleRequestVideo = () => setOutgoingRequestPeer(selectedUser)

  const updateRecentConversations = async () => {
    const conversations = await getConversations(client, user)
    setRecentUsers(uniqBy(conversations, 'twilioId'))
  }

  const handleChannelUpdated = async ({ channel }) => {
    await autoJoinChannelWhenInvited(channel)
    updateRecentConversations()
  }

  const toggleTextWindowDuringVideo = () => setShowTextWindowDuringVideo(show => !show)

  const init = async () => {
    if (accessToken) {
      const users = await getSuggestedUsers(accessToken)
      setSuggestedUsers(users)
    }

    client.on('channelUpdated', handleChannelUpdated)
    updateRecentConversations(client)
  }

  useEffect(() => {
    if (activeVideoCallPeer) {
      setSelectedUser(activeVideoCallPeer)
    }
  }, [activeVideoCallPeer])

  useEffect(() => {
    init()
    return () => client.removeListener('channelUpdated', handleChannelUpdated)
  }, [user.twilioId, twilioAccessToken])

  return (
    <div className={ cx(style.main, {
      [style.hasSelectedUser]: !!selectedUser,
      [style.hasActiveVideoCall]: !!activeVideoCallPeer,
      [style.hasVisibleTextWindowDuringVideo]: !!activeVideoCallPeer && showTextWindowDuringVideo,
    }) }>
      <div className={ style.sidebar }>
        <Sidebar
          selectedUser={ selectedUser }
          recentUsers={ recentUsers }
          suggestedUsers={ suggestedUsers }
          onSelectUser={ setSelectedUser }
        />
      </div>
      <div className={ style.videobar }>
        <Video
          client={ client }
          user={ user }
          outgoingRequestPeer={ outgoingRequestPeer }
          onOutgoingCallResolved={ () => setOutgoingRequestPeer(undefined) }
          onVideoCallStatusChange={ setActiveVideoCallPeer }
          accessToken={ twilioAccessToken }
          onToggleTextWindow={ toggleTextWindowDuringVideo }
        />
      </div>
      <div className={ style.textbar }>
      {
        selectedUser ?
          <Channel
            client={ client }
            user={ user }
            peer={ selectedUser }
            twilioAccessToken={ twilioAccessToken }
            hasActiveVideoCall={ activeVideoCallPeer }
            onRequestVideo={ handleRequestVideo }
            onClose={ activeVideoCallPeer ?
              toggleTextWindowDuringVideo :
              () => setSelectedUser(undefined)
            }
          /> :
          <div className={ style.intro }>
            <h2 className={ style.welcome }>
              <Trans>¡Bienvenido!</Trans>
            </h2>
            <p>
              <Trans>
                Continua con tus conversaciones abiertas o inicia una nueva conversación
                eligiendo uno de los contactos que te sugerimos o buscando una persona
                con la que ya has hablado.
              </Trans>
            </p>
            <p>
              <Trans>Los navegadores compatibles para esta funcionalidad son: Chrome, Firefox y Safari.</Trans>
            </p>
          </div>
      }
      </div>
    </div>
  )
}

export default flowRight(
  withLocale,
  withAuth,
  withTwilioClient
)(Chat)
