import React, { useState, useEffect, useRef } from 'react'
import cx from 'classnames'
import { Trans } from '@lingui/macro'
import { groupBy, isEqual, last, map, noop, pick } from 'lodash'
import { withLocale } from 'components/common/Locale'
import Title from './Title'
import Message from './Message'
import { asDDMMYY } from '~/utils/date'
import CloseIcon from '~/assets/images/icon/close.svg'
import VideoIcon from '~/assets/images/icon/video.svg'
import { formatInteractiveMessage } from '~/service/chat'
import AttachmentIcon from '~/assets/images/icon/attachment.svg'
import style from './text.module.scss'

export default withLocale(({
  i18n,
  hasActiveVideoCall,
  user,
  peer,
  messages,
  lastConsumedMessageIndex,
  onClose,
  onRequestVideo,
  onSubmitMessage = noop,
  onAllMessagesConsumed = noop,
}) => {
  const [currentMessage, setCurrentMessage] = useState('')

  const handleSubmit = () => {
    setCurrentMessage('')
    onSubmitMessage(currentMessage)
  }

  const handleTextAreaKeyDown = ev => {
    if (ev.key === 'Enter') {
      ev.preventDefault()
      handleSubmit()
    }
  }

  const handleFileAttached = ev => {
    if (ev.target.files.length) {
      const file = ev.target.files[0]
      const fd = new FormData()
      fd.append(file.name, file)
      onSubmitMessage(fd)
      ev.target.value = null
    }
  }

  const handleContainerScroll = () => {
    const { current: element } = container
    if (element.offsetHeight + element.scrollTop === element.scrollHeight) {
      onAllMessagesConsumed()
    }
  }

  const lastConsumedMessageRef = useRef()
  const container = useRef()

  useEffect(() => {
    if (lastConsumedMessageRef.current) {
      lastConsumedMessageRef.current.scrollIntoView({behavior: 'smooth'})
    }
  }, [lastConsumedMessageIndex, lastConsumedMessageRef.current])

  useEffect(() => {
    if (container.current) {
      container.current.addEventListener('scroll', handleContainerScroll)
    }
    return () => container.current.removeEventListener('scroll', handleContainerScroll)
  }, [container.current])

  const groupedByDay = groupBy(messages, ({ dateUpdated }) => asDDMMYY(dateUpdated))
  const formatMessage = formatInteractiveMessage(i18n)

  return (
    <div className={ style.main }>
      <div className={ style.header }>
        <div
          onClick={ onClose }
          className={ cx(style.close, {
            [style.hasActiveVideoCall]: hasActiveVideoCall
          }) }
          role="button"
        >
          <CloseIcon />
        </div>
        <Title
          className={ style.title }
          user={ peer }
        />
        {
          !hasActiveVideoCall ?
            <div
              onClick={ onRequestVideo }
              className={ style.camera }
            >
              <VideoIcon />
            </div> :
            null
        }
      </div>
      <div
        ref={ container }
        className={ style.messages }
      >
      {
         map(groupedByDay, (messages, day, index) => (
          <div key={ day }>
            <div className={ style.day }>
              <span>{ day }</span>
            </div>
            {
              map(messages, (message) =>
                <Message
                  key={ `${ message.dateUpdated.toString() }${ message.index }` }
                  ref={ message.index === lastConsumedMessageIndex ? lastConsumedMessageRef : undefined }
                  { ...pick(message, ['type', 'body', 'media', 'dateUpdated']) }
                  className={ style.message }
                  user={ message.attributes }
                  formatMessage={ formatMessage }
                  isOwnMessage={ user.twilioId === message.author }
                />
              )
            }
          </div>
         ))
      }
      </div>
      <div className={ style.editor }>
        <textarea
          onKeyDown={ handleTextAreaKeyDown }
          className={ style.textarea }
          value={ currentMessage }
          onChange={ ev => setCurrentMessage(ev.target.value) }
          rows={ 1 }
        />
        <button
          className={ cx(style.button, style.attachment) }
        >
          <input className={ style.fileInput } type="file" onChange={ handleFileAttached } />
          <AttachmentIcon />
        </button>
        <button
          className={ cx(style.button, style.send) }
          onClick={ handleSubmit }
          disabled={ !currentMessage }
        >
          <Trans>Enviar</Trans>
        </button>
      </div>
    </div>
  )
})
