import React, { Component } from 'react'
import { Chat as Layout } from 'components/layout/Layout'
import Chat from 'components/section/Chat'
import { AuthGuard } from 'components/common/Auth'

export default ({ location }) => (
  <AuthGuard location={ location }>
    <Layout location={ location }>
      <Chat />
    </Layout>
  </AuthGuard>
)
