import React, { useState, useEffect, useRef } from 'react'
import { map } from 'lodash'
import { incrementally } from '~/utils/react'

export default ({ className, track }) => {
  const ref = useRef()

  useEffect(() => {
    ref.current.appendChild(track.attach())
    return () => {
      try {
        const els = track.detach()
        els.forEach(el => el.remove())
      } catch (e) {

      }
    }
  }, [track])

  return <div className={ className } ref={ ref } />
}
