import React from 'react'
import cx from 'classnames'
import { get, map } from 'lodash'
import User from './User'
import style from './users.module.scss'

export default ({ className, items, selectedItem, onSelectItem, isSuggestion }) => (
  <div role="list" className={ cx(style.main, className) }>
    {
      map(items.filter(Boolean), item =>
        <User
          key={ item.twilioId }
          isActive={ get(selectedItem, 'twilioId') === item.twilioId }
          className={ style.item }
          onClick={ () => onSelectItem(item) }
          isSuggestion={ isSuggestion }
          user={ item }
          dateUpdated={ item.dateUpdated }
          hasUnconsumedMessages={ item.hasUnconsumedMessages }
        />
      )
    }
    </div>
)