import React, { useState } from 'react'
import cx from 'classnames'
import { flowRight, get, includes, map, sortBy } from 'lodash'
import { Trans } from '@lingui/macro'
import { withLocale } from 'components/common/Locale'
import { withAuth } from 'components/common/Auth'
import { searchUser } from '~/service/user'
import SearchUsers from './SearchUsers'
import Users from './Users'
import SuggestedUsers from './SuggestedUsers'
import { debounce } from '~/utils/async'
import style from './sidebar.module.scss'

const searchUserDebounced = debounce(searchUser, 600)

const Sidebar = ({ selectedUser, accessToken, i18n, recentUsers, suggestedUsers = [], onSelectUser }) => {
  const [foundUsers, setFoundUsers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [isAwaitingResults, setIsAwaitingSearchResults] = useState(false)
  const [shouldHideUserSuggestions, setShouldHideUserSuggestions] = useState(false)

  const handleSearch = async term => {
    setFoundUsers([])
    if (term && term.length > 2) {
      setIsAwaitingSearchResults(true)
      const results = await searchUserDebounced(accessToken, term)
      setFoundUsers(results)
      setIsAwaitingSearchResults(false)
    }
    setSearchTerm(term)
  }

  const users = (searchTerm ? foundUsers : recentUsers) || []
  const filteredSuggestedUsers = suggestedUsers
    .filter(({ twilioId }) => !includes([
      ...map(recentUsers, 'twilioId'),
      get(selectedUser, 'twilioId')
    ], twilioId))

  return (
    <div className={ style.main }>
      <SearchUsers
        isAwaitingResults={ isAwaitingResults }
        onSearch={ handleSearch }
      />
      <div className={ style.container }>
        {
          users.length > 0 ?
            <Users
              className={ style.users }
              items={ sortBy(users, 'dateUpdated').reverse() }
              selectedItem={ selectedUser }
              onSelectItem={ onSelectUser }
            /> :
            <div className={ style.empty }>
            {
              !isAwaitingResults ?
                searchTerm && searchTerm.length > 2 ?
                  <p className={ style.noResults }>
                    <Trans>No hay usuarios que coincidan con tu búsqueda.</Trans>
                  </p> :
                  <p>
                    <Trans>Escribe el nombre de la persona para iniciar una conversación.</Trans>
                  </p> :
                  null
            }
            </div>
        }
        {
          !searchTerm && !shouldHideUserSuggestions && filteredSuggestedUsers.length > 0 ?
            <SuggestedUsers
              items={ filteredSuggestedUsers }
              onSelectItem={ onSelectUser }
              onClose={ () => setShouldHideUserSuggestions(true) }
            /> :
            null
        }
      </div>
    </div>
  )
}

export default flowRight(
  withAuth,
  withLocale,
)(Sidebar)