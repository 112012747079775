import React, { useState } from 'react'
import cx from 'classnames'
import Loading from 'components/common/Loading'
import { Trans } from '@lingui/macro'
import { withLocale } from 'components/common/Locale'
import WriteIcon from '~/assets/images/icon/write.svg'
import CloseIcon from '~/assets/images/icon/close.svg'
import style from './searchusers.module.scss'

export default withLocale(({ i18n, isAwaitingResults, onSearch, shouldShowUsersPlaceholder }) => {
  const [isFocused, setFocused] = useState(false)
  const [term, setTerm] = useState('')

  const handleChange = value => {
    setTerm(value)
    onSearch(value)
  }

  return (
    <div className={ style.main }>
      <input
        className={ style.input }
        value={ term }
        onFocus={ () => setFocused(true) }
        onBlur={ () => setFocused(false) }
        onChange={ ev => handleChange(ev.target.value) }
      />
      {
        !term ?
          <span className={ style.placeholder }>
            <WriteIcon />
            <Trans>Nueva conversación</Trans>
          </span> :
          <span
            className={ style.close }
            onClick={ () => handleChange('') }
          >
            <CloseIcon />
          </span>
      }
      {
        isAwaitingResults ?
          <Loading /> :
          null
      }
    </div>
  )
})
