import React, { forwardRef, useState, useEffect } from 'react'
import cx from 'classnames'
import { get } from 'lodash'
import Avatar from './Avatar'
import { asHHMM } from '~/utils/date'
import Linkify from 'react-linkify'
import PaperclipIcon from '~/assets/images/icon/paperclip.svg'
import style from './message.module.scss'

const renderAnchor = (href, text, key) =>
  <a href={ href } key={ key } target="_blank">
    { text }
  </a>

const AttachmentLink = ({ media }) => {
  const [url, setContentUrl] = useState('')
  useEffect(() => {
    media.getContentUrl().then(setContentUrl)
  }, [media])

  const contents = (
    <>
      <PaperclipIcon />
      { get(media, 'state.filename') }
    </>
  )

  return url ?
    <a className={ style.link } href={ url } target="_blank">{ contents }</a> :
    contents
}

export default forwardRef(({ formatMessage, className, media, type, user, body, dateUpdated, isOwnMessage }, ref) => (
  <div
    ref={ ref }
    className={ cx(style.main, {
      [style.isOwnMessage]: isOwnMessage,
    }, className) }
  >
    <Avatar className={ style.avatar } user={ user } />
    <div className={ style.message }>
      <span className={ style.info }>
        { user.firstName }, { asHHMM(dateUpdated) }
      </span>
      <Linkify componentDecorator={ renderAnchor }>
        <blockquote className={ style.body }>
        {
          type ==='media' ?
            <AttachmentLink media={ media } /> :
            formatMessage(body)
        }
        </blockquote>
      </Linkify>
    </div>
  </div>
))