import React, { useState, useEffect } from 'react'
import { get, over, noop } from 'lodash'
import Call from './Call'
import Connecting from './Connecting'
import {
  listenVideoRequest,
  listenVideoAccept,
  listenVideoReject,
  listenVideoEnd,
  requestVideo,
  acceptVideo,
  rejectVideo,
  endVideo,
} from '~/service/chat'

export default ({ accessToken, client, user, outgoingRequestPeer = {}, onOutgoingCallResolved = noop, onVideoCallStatusChange, onToggleTextWindow }) => {
  const [incomingRequestPeer, setIncomingRequestPeer] = useState(undefined)
  const [activePeer, setActivePeer] = useState(undefined)


  const handleOutgoingRequest = () => {
    requestVideo(client, user, outgoingRequestPeer)
  }

  const handleOutgoingEnd = () => {
    endVideo(client, user, activePeer)
    setActivePeer(undefined)
    onOutgoingCallResolved()
  }

  const handleOutgoingAbort = () => {
    endVideo(client, user, outgoingRequestPeer)
    onOutgoingCallResolved()
  }

  const acceptVideoRequest = () => {
    acceptVideo(client, user, incomingRequestPeer)
    setActivePeer(incomingRequestPeer)
    setIncomingRequestPeer(undefined)
  }

  const rejectVideoRequest = () => {
    rejectVideo(client, user, incomingRequestPeer)
    setIncomingRequestPeer(undefined)
  }

  // incoming messages
  const handleIncomingRequest = (message, peer) => {
    setIncomingRequestPeer(peer)
  }

  const handleIncomingAccept = (message, peer) => {
    setActivePeer(peer)
    onOutgoingCallResolved()
  }

  const handleIncomingReject = (message, peer) => {
    setActivePeer(undefined)
    onOutgoingCallResolved()
  }

  const handleIncomingEnd = (message, peer) => {
    setActivePeer(undefined)
    setIncomingRequestPeer(undefined)
  }

  let handlers = {}

  const unlistenInteractiveMessages = channel => {
    channel.removeListener('messageAdded', handlers[channel.sid] || noop)
  }

  const listenInteractiveMessages = channel => {
    const handler = over([
      listenVideoRequest(user, handleIncomingRequest),
      listenVideoAccept(user, handleIncomingAccept),
      listenVideoReject(user, handleIncomingReject),
      listenVideoEnd(user, handleIncomingEnd),
    ])
    handlers[channel.sid] = handler
    channel.on('messageAdded', handler)
  }

  const mount = async () => {
    const channels = await client.getSubscribedChannels()
    channels.items.forEach(over([
      unlistenInteractiveMessages,
      listenInteractiveMessages,
    ]))

    client.on('channelAdded', channel => listenInteractiveMessages(channel))
  }

  const unmount = async () => {
    if (client) {
      const channels = await client.getSubscribedChannels()
      channels.items.forEach(unlistenInteractiveMessages)
    }
  }

  useEffect(() => {
    onVideoCallStatusChange(activePeer)
  }, [activePeer])

  useEffect(() => {
    mount()
    return unmount
  }, [])

  useEffect(() => {
    if (outgoingRequestPeer.twilioId) {
      handleOutgoingRequest()
    }
  }, [outgoingRequestPeer.twilioId])

  return (
    outgoingRequestPeer.twilioId ?
      <Connecting
        user={ user }
        peer={ outgoingRequestPeer }
        onHangUp={ handleOutgoingAbort }
      /> :
      incomingRequestPeer ?
        <Connecting
          user={ user }
          peer={ incomingRequestPeer }
          onHangUp={ rejectVideoRequest }
          onPickUp={ acceptVideoRequest }
          isIncomingRequest
        /> :
        activePeer ?
          <Call
            user={ user }
            peer={ activePeer }
            onIncomingEndVideo={ handleIncomingEnd }
            onOutgoingEndVideo={ handleOutgoingEnd }
            onToggleTextWindow={ onToggleTextWindow }
            accessToken={ accessToken }
          /> :
          null
  )
}