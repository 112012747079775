import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import { map, noop, size } from 'lodash'
import { connectVideo } from '~/service/chat'
import Loading from 'components/common/Loading'
import Title from '../Title'
import Avatar from '../Avatar'
import Button from './Button'
import Track from './Track'
import { incrementally } from '~/utils/react'

import style from './call.module.scss'

const participantTracks = participant => Array.from(participant.tracks.values())

export default ({ accessToken, onIncomingEndVideo, onOutgoingEndVideo, onToggleTextWindow, user, peer }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [room, setRoom] = useState(undefined)
  const [tracks, setTracks] = useState([])
  const [addTrack, removeTrack] = incrementally(setTracks)
  const handleUnload = () => room.disconnect()

  const init = async () => {
    if (!room) {
      setIsLoading(true)
      setTracks([])
      const room = await connectVideo(accessToken, user, peer)
      room.on('participantDisconnected', onIncomingEndVideo)
      room.on('trackSubscribed', addTrack)
      room.on('trackUnsubscribed', removeTrack)
      setTracks(map(room.participants, participantTracks))
      setRoom(room)
      setIsLoading(false)
      window.addEventListener('beforeunload', handleUnload)
    }
  }

  useEffect(() => {
    init()
    return () => {
      if (room) {
        room.localParticipant.tracks.forEach(track => track.stop())
        room.removeListener('participantDisconnected', onIncomingEndVideo)
        room.removeListener('trackSubscribed', addTrack)
        room.removeListener('trackUnsubscribed', removeTrack)
        window.removeEventListener('beforeunload', handleUnload)
        room.disconnect()
      }
    }
  }, [peer.twilioId, room])

  return (
    isLoading || !room ?
      <Loading isFullScreen /> :
      <CallPresentation
        peer={ peer }
        onHangUp={ onOutgoingEndVideo }
        onToggleTextWindow={ onToggleTextWindow }
        localTrack={
          room.localParticipant ?
            map(participantTracks(room.localParticipant), (track, index) =>
              <Track
                key={ index }
                className={ style.localTrack }
                track={ track }
              />
            ) :
            null
        }
        remoteTracks={
          map(tracks, (track, index) =>
            <Track
              key={ index }
              className={ cx(style.remoteTrack, {
                [style.videoTrack]: track.kind === 'video'
              }) }
              track={ track }
            />
          )
        }
      />
  )
}

const CallPresentation = ({ localTrack, remoteTracks, peer, onHangUp = noop, onToggleTextWindow = noop }) => (
  <div className={ style.main }>
    { localTrack }
    { remoteTracks }
    <Title user={ peer } className={ style.title } />
    <div className={ style.actions }>
      <Button
        isHangUp
        onClick={ onHangUp }
      />
      <Button
        className={ style.toggleTextWindow }
        onClick={ onToggleTextWindow }
        isToggleText
      />
    </div>
  </div>
)